<template>
	<div class="ybd-view">
		<designHeader :isSaving="isSaving" :showBtn="showBtn" :hasEdit="hasEdit" :isJz="isJz"></designHeader>
		<div class="container">
			<designLeftNav :fromType='fromType' :identityType='identityType'></designLeftNav>
			<div class="main">
				<div class="full-wrap">
					<div class="wrap question_types" v-show="equipment == 1">
						<div class="tab_wrap" v-show="!headerImageListShowFlag">
							<div class="nav">
								<div class="item" :class="{active: tabNavCurrent === 0}" @click="tabNavCurrent = 0">控件</div>
								<span class="line"></span>
								<div class="item" :class="{active: tabNavCurrent === 1}" @click="tabNavCurrent = 1">大纲</div>
							</div>
							<div class="tab_list">
								<transition name="fade">
									<div class="tab_item widget" v-show="tabNavCurrent === 0">
										<div class="model_warp" v-for="widgetGroup in widgetGroupList" :key="widgetGroup._id">
											<div class="title">{{widgetGroup.name}}</div>
											<draggable v-model="widgetGroup.question" v-bind="{group:{name: 'itxst',pull:'clone'},sort: false,}" chosenClass="d-chosen" filter=".drag-tips,.drag-site" :move="dragMove" @end="dragEnd">
												<transition-group class="list widget-list" :class="'widget-list'+widgetGroup._id">
													<div class="item" v-for="item in widgetGroup.question" :key="item._id">
														<span class="txt">{{item.topicName}}</span>
													</div>
												</transition-group>
											</draggable>
										</div>
									</div>
								</transition>
								<transition name="fade">
									<div class="tab_item catalogue" v-show="tabNavCurrent === 1">
										<ul class="list" v-if="list.length > 0">
											<li class="item" v-for="(item,index) in list" :key="'cata' + index">
												<a :class="{'active': o == currentWidget}" v-for="(o,i) in item.children" :key="'c' + i" :href="'#' + o.uuid" @click="setShow = true,currentWidget = o">{{o.topicName}}</a>
											</li>
										</ul>
										<noData v-else text="暂时还没有表单控件哦！"></noData>
									</div>
								</transition>
							</div>
						</div>
						<div class="tab_wrap" v-show="headerImageListShowFlag">
							<!-- 页眉图片-->
							<transition name="widthZoom">
								<div class="header_image">
									<div class="main">
										<div class="head">
											<span class="label">主题</span>
											<i class="iconfont icon-shouqi" @click="headerImageListShowFlag = false"></i>
										</div>
										<div class="style_nav">
											<span class="label">风格</span>
											<ul class="list cl">
												<li :class="{active: headerImgTypeId == ''}" @click="headerImgTypeId = ''">全部</li>
												<li :class="{active: item._id == headerImgTypeId}" v-for="item in headerImgTypeList" :key="item._id" @click="headerImgTypeId = item._id">{{item.name}}</li>
											</ul>
										</div>
										<div class="img_list">
											<div class="item upload">
												<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" accept="image/*" :before-upload="beforeImgUpload" :on-success="handleHeaderImgSuccess" :show-file-list="false">
													<i class="el-icon-upload avatar-uploader-icon"></i>
													<span>上传图片</span>
												</el-upload>
											</div>
											<el-scrollbar v-if="headerImgList.length>0">
												<div class="item" :class="{'active': item.imgUrl == globalProperties.formHeader.imgSrc}" v-for="item in headerImgList" :key="item._id" @click="globalProperties.formHeader.imgSrc = item.imgUrl">
													<span class="bat">
														<i class="el-icon-check"></i>
													</span>
													<el-image class="img" :src="item.imgUrl" lazy></el-image>
												</div>
											</el-scrollbar>
											<noData v-else text="暂时还没有图片哦！"></noData>
										</div>
									</div>
								</div>
							</transition>
						</div>
						<div class="main_area">
							<div class="edit_cont">
								<div class="edit_wrap">
									<div class="equipment_switch">
										<div class="btn el-icon-monitor" :class="{'active': equipment == 1}" @click="equipment = 1"></div>
										<div class="btn el-icon-mobile" :class="{'active': equipment == 2}" @click="equipment = 2"></div>
									</div>
									<div class="edit_inner" @click="setShow = true,currentWidget =''" :style="{width: globalStyle.formWidth + 'px'}">
										<div class="form-logo"></div>
										<div class="form-head" v-show="globalProperties.formHeader.isShow">
											<div v-show="globalProperties.formHeader.type === '文字'" id="formHeadText" class="txt" key="formHeadText">{{globalProperties.formHeader.textCont}}</div>
											<div v-show="globalProperties.formHeader.type === '图片'" class="img" key="formHeadImg">
												<img v-if="globalProperties.formHeader.imgSrc" :style="{height: globalProperties.formHeader.imgHeight + 'px'}" :src="globalProperties.formHeader.imgSrc">
											</div>
										</div>
										<div class="edit-head">
											<div id="formTitle" class="form-title" v-show="globalProperties.formTitle.isShow">
												<el-tooltip effect="dark" content="点击编辑表单标题" placement="top-start" :disabled="formTitleTips">
													<el-input type="textarea" ref="titleText" resize="none" :autosize="true" placeholder=" 请输入表单标题" v-model="globalProperties.formTitle.textCont" @focus="formTitleTips = true" @blur="formTitleTips = false"></el-input>
												</el-tooltip>
											</div>
											<div id="formDescript" class="form-descript" v-show="globalProperties.formDescript.isShow">
												<el-tooltip effect="dark" content="点击编辑表单描述" placement="top-start" :disabled="formDescriptTips">
													<el-input type="textarea" ref="descriptText" :autosize="true" resize="none" placeholder=" 请输入表单描述" v-model="globalProperties.formDescript.textCont" @focus="formDescriptTips = true" @blur="formDescriptTips = false"></el-input>
												</el-tooltip>
											</div>
										</div>
										<draggable v-model="list" class="parent-wrap" :class="{'is_dragging': isDragging}"  group="itxst" ghostClass="s-ghost" filter=".drag-tips,.drag-site" >
											<transition-group class="parent-list">
												<template v-if="list.length > 0">
													<div class="parent-item" v-for="(item,index) in list" :key="'p' + index">
														<div v-if="isDragging" class="drag-site"></div>
														<draggable v-model="item.children" class="children-wrap" group="itxst" ghostClass="s-ghost" filter=".drag-site" :move="childSortMove" @end="childSortEnd">
															<transition-group class="children-list" :class="[{'full': item.children && item.children.length > 1},{'empty': item.children && item.children.length == 0}]" :index="index">
																<div class="children-item" :class="['ybd-' + o.type,{'active': o == currentWidget && !isDragging}]" v-for="(o,i) in item.children" :key="'c' + i" @click.stop="setShow = true,currentWidget = o">
																	<ybdWidget :detial="o" />
																	<div class="cover" v-show="!isDragging" :style="{height: 'calc( 100% - ' + o.itemStyle.interval + 'px )'}"></div>
																	<div class="widget_operate" v-show="!isDragging">
																		<div class="btn el-icon-document-copy" tip="复制" @click.stop="copyWidget(index,i)"></div>
																		<div class="btn el-icon-delete" tip="删除" @click.stop="deleteWidget(index,i)"></div>
																	</div>
																</div>
															</transition-group>
														</draggable>
														<div v-if="isDragging" class="drag-site"></div>
													</div>
												</template>
												<div v-else key="no-list" class="drag-tips" :class="{hide: !dragTipsFlag}">
													<div class="iconfont icon-yidong"></div>
													<div class="txt">拖拽控件至此处</div>
												</div>
											</transition-group>
										</draggable>
										<div id="submitBtn" class="submit_btn" v-show="list.length > 0">
											<el-button type="primary">{{globalStyle.submitBtnStyle.text}}</el-button>
										</div>
									</div>
								</div>
								<transition name="widthZoom">
									<div class="set_area" v-show="setShow">
										<div class="close_btn" @click="setShow = false">
												<i class="iconfont icon-shouqikuaijin"></i>
										</div>
										<div class="set_list">
											<!-- 控件设置 -->
											<widgetSet v-if="currentWidget && currentWidget != ''" :detial="currentWidget" />
											<!-- 主界面设置 -->
											<div class="set_item" v-else>
												<div class="title">表单设置</div>
												<div class="list">
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">主题颜色</span>
															</div>
															<el-color-picker v-model="globalStyle.themeColor" size="mini" show-alpha></el-color-picker>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单宽度</span>
															</div>
															<div class="f_cont silder">
																<el-slider v-model="globalStyle.formWidth" :min="300" :max="1920" :step="10"></el-slider>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">页眉</span>
																<div class="explain">
																	<i class="el-icon-info"></i>
																	<div class="cont">
																		<i class="angle"></i>
																		<div class="tips-main">
																			<div class="imgbox">
																				<img src="@/assets/images/header_pc.png" alt="">
																			</div>
																			<div class="infobox">
																				<div class="tips-head">电脑页眉示例</div>
																				<div class="tips-txt">
																					<p class="p1">建议尺寸：</p>
																					<p class="p2">宽度750px，高度300px</p>
																					<p class="p1">格式要求：</p>
																					<p class="p2">JPEG、PNG、JPG和GIF</p>
																					<p class="p1">图片大小：</p>
																					<p class="p2">5M以下</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<el-switch v-model="globalProperties.formHeader.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalProperties.formHeader.isShow">
															<div class="sub_item">
																<div class="sub_label">展示类型</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalProperties.formHeader.type" size="mini">
																		<el-radio-button label="文字"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<template v-if="globalProperties.formHeader.type === '文字'">
																<div class="sub_item">
																	<div class="sub_label">文字内容</div>
																	<div class="sub_cont">
																		<el-input :class="{'error': globalProperties.formHeader.isError}" v-model="globalProperties.formHeader.textCont" placeholder="请输入内容"></el-input>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalProperties.formHeader.textStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalProperties.formHeader.textStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalProperties.formHeader.textStyle.fontWight == 'bold'}" @click="globalProperties.formHeader.textStyle.fontWight = globalProperties.formHeader.textStyle.fontWight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalProperties.formHeader.textStyle.fontStyle == 'italic'}" @click="globalProperties.formHeader.textStyle.fontStyle = globalProperties.formHeader.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalProperties.formHeader.textStyle.textDecoration == 'underline'}" @click="globalProperties.formHeader.textStyle.textDecoration = globalProperties.formHeader.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">对齐方式</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="globalProperties.formHeader.textStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景颜色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="globalProperties.formHeader.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="formHeadTextPaddingFlag" size="mini">
																			<el-radio-button label="上下"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="formHeadTextPaddingFlag == '上下'">
																	<div class="sub_label">上下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalProperties.formHeader.textStyle.paddingVertical" :max="100"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="formHeadTextPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalProperties.formHeader.textStyle.paddingHorizontal" :max="100"></el-slider>
																	</div>
																</div>
															</template>
															<template v-else>
																<div class="sub_item">
																	<div class="sub_label">选择图片</div>
																	<div class="sub_cont">
																			<div class="avatar-uploader">
																				<div class="el-upload" @click="headerImageListShowFlag = true">
																					<div v-if="globalProperties.formHeader.imgSrc" class="avatar">
																						<img :src="globalProperties.formHeader.imgSrc">
																						<div class="el-icon-error" @click.stop="globalProperties.formHeader.imgSrc = ''"></div>
																					</div>
																					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																				</div>
																			</div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">图片高度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalProperties.formHeader.imgHeight" :min="40" :max="500"></el-slider>
																	</div>
																</div>
															</template>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">背景</span>
																<div class="explain">
																	<i class="el-icon-info"></i>
																	<div class="cont">
																		<i class="angle"></i>
																		<div class="tips-main">
																			<div class="imgbox">
																				<img src="@/assets/images/background_pc.png" alt="">
																			</div>
																			<div class="infobox">
																				<div class="tips-head">电脑背景示例</div>
																				<div class="tips-txt">
																					<p class="p1">建议尺寸：</p>
																					<p class="p2">宽度1920px，高度1080px</p>
																					<p class="p1">格式要求：</p>
																					<p class="p2">JPEG、PNG、JPG和GIF</p>
																					<p class="p1">图片大小：</p>
																					<p class="p2">5M以下</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<el-switch v-model="globalProperties.formBg.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalProperties.formBg.isShow">
															<div class="sub_item">
																<div class="sub_label">背景类型</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalProperties.formBg.type" size="mini">
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="globalProperties.formBg.type == '颜色'">
																<div class="sub_label">颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="globalProperties.formBg.color" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-show="globalProperties.formBg.type == '图片'">
																<div class="sub_label">图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" accept="image/*" :before-upload="beforeImgUpload" :on-success="handleFormBgSuccess" :show-file-list="false">
																		<div v-if="globalProperties.formBg.imgSrc" class="avatar">
																			<img :src="globalProperties.formBg.imgSrc">
																			<div class="el-icon-error" @click.stop="globalProperties.formBg.imgSrc = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单标题</span>
															</div>
															<el-switch v-model="globalProperties.formTitle.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalProperties.formTitle.isShow">
															<div class="sub_item">
																<div class="sub_label">文字内容</div>
																<div class="sub_cont">
																	<el-input :class="{'error': globalProperties.formTitle.isError}" v-model="globalProperties.formTitle.textCont" placeholder="请输入表单标题"></el-input>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="globalProperties.formTitle.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="globalProperties.formTitle.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: globalProperties.formTitle.textStyle.fontWight == 'bold'}" @click="globalProperties.formTitle.textStyle.fontWight = globalProperties.formTitle.textStyle.fontWight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: globalProperties.formTitle.textStyle.fontStyle == 'italic'}" @click="globalProperties.formTitle.textStyle.fontStyle = globalProperties.formTitle.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: globalProperties.formTitle.textStyle.textDecoration == 'underline'}" @click="globalProperties.formTitle.textStyle.textDecoration = globalProperties.formTitle.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="formTitlePaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="formTitlePaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalProperties.formTitle.textStyle.paddingTop" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="formTitlePaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalProperties.formTitle.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="formTitlePaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalProperties.formTitle.textStyle.paddingBottom" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景设置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalProperties.formTitle.textStyle.bgType" size="mini">
																		<el-radio-button label="默认"></el-radio-button>
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="globalProperties.formTitle.textStyle.bgType == '颜色'">
																<div class="sub_label">选择颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="globalProperties.formTitle.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-show="globalProperties.formTitle.textStyle.bgType == '图片'">
																<div class="sub_label">上传图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" accept="image/*" :before-upload="beforeImgUpload" :on-success="handleFormTitleSuccess" :show-file-list="false">
																		<div v-if="globalProperties.formTitle.textStyle.backgroundImage" class="avatar">
																			<img :src="globalProperties.formTitle.textStyle.backgroundImage">
																			<div class="el-icon-error" @click.stop="globalProperties.formTitle.textStyle.backgroundImage = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单描述</span>
															</div>
															<el-switch v-model="globalProperties.formDescript.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalProperties.formDescript.isShow">
															<div class="sub_item">
																<div class="sub_label">文字内容</div>
																<div class="sub_cont">
																	<el-input :class="{'error': globalProperties.formDescript.isError}" v-model="globalProperties.formDescript.textCont" type="textarea" :rows="3" resize="none" placeholder="请输入表单描述"></el-input>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="globalProperties.formDescript.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="globalProperties.formDescript.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: globalProperties.formDescript.textStyle.fontWight == 'bold'}" @click="globalProperties.formDescript.textStyle.fontWight = globalProperties.formDescript.textStyle.fontWight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: globalProperties.formDescript.textStyle.fontStyle == 'italic'}" @click="globalProperties.formDescript.textStyle.fontStyle = globalProperties.formDescript.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: globalProperties.formDescript.textStyle.textDecoration == 'underline'}" @click="globalProperties.formDescript.textStyle.textDecoration = globalProperties.formDescript.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="formDescriptPaddingFlag" size="mini">
																		<el-radio-button label="上下"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="formDescriptPaddingFlag == '上下'">
																<div class="sub_label">上下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalProperties.formDescript.textStyle.paddingVertical" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="formDescriptPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalProperties.formDescript.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">提交按钮</span>
															</div>
															<div class="fold_btn" :class="{active: submitBtnFoldFlag}" @click="submitBtnFoldFlag = !submitBtnFoldFlag">
																<span>{{submitBtnFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="submitBtnFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">按钮文字</div>
																	<div class="sub_cont">
																		<el-input :class="{'error': globalStyle.submitBtnStyle.isError}" v-model="globalStyle.submitBtnStyle.text" maxlength="10" placeholder="请输入按钮文字"></el-input>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮宽度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.width" :max="500" :min="40"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮高度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.height" :min="20"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮位置</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="globalStyle.submitBtnStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalStyle.submitBtnStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalStyle.submitBtnStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalStyle.submitBtnStyle.fontWight == 'bold'}" @click="globalStyle.submitBtnStyle.fontWight = globalStyle.submitBtnStyle.fontWight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalStyle.submitBtnStyle.fontStyle == 'italic'}" @click="globalStyle.submitBtnStyle.fontStyle = globalStyle.submitBtnStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalStyle.submitBtnStyle.textDecoration == 'underline'}" @click="globalStyle.submitBtnStyle.textDecoration = globalStyle.submitBtnStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">边框</div>
																	<div class="sub_cont border_style">
																		<el-input v-model="globalStyle.submitBtnStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																		<el-select v-model="globalStyle.submitBtnStyle.borderStyle" placeholder="请选择">
																			<el-option label="实线" value="solid"></el-option>
																			<el-option label="虚线" value="dashed"></el-option>
																			<el-option label="点线" value="dotted"></el-option>
																		</el-select>
																		<el-color-picker v-model="globalStyle.submitBtnStyle.borderColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">圆角</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.borderRadius" :max="200"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="globalStyle.submitBtnStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">外边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="submitBtnPaddingFlag" size="mini">
																			<el-radio-button label="上"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																			<el-radio-button label="下"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="submitBtnPaddingFlag == '上'">
																	<div class="sub_label">上</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.paddingTop"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="submitBtnPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.paddingHorizontal" :max="500"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="submitBtnPaddingFlag == '下'">
																	<div class="sub_label">下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.paddingBottom"></el-slider>
																	</div>
																</div>
															</div>
														</transition>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">内容区</span>
															</div>
															<div class="fold_btn" :class="{active: contFoldFlag}" @click="contFoldFlag = !contFoldFlag">
																<span>{{contFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="contFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="contPaddingFlag" size="mini">
																			<el-radio-button label="上"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																			<el-radio-button label="下"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="contPaddingFlag == '上'">
																	<div class="sub_label">上</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.contStyle.paddingTop"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="contPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.contStyle.paddingHorizontal" :max="200"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="contPaddingFlag == '下'">
																	<div class="sub_label">下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.contStyle.paddingBottom"></el-slider>
																	</div>
																</div>
															</div>
														</transition>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单项样式</span>
															</div>
															<div class="fold_btn" :class="{active: itemFoldFlag}" @click="itemFoldFlag = !itemFoldFlag">
																<span>{{itemFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="itemFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">边框</div>
																	<div class="sub_cont border_style">
																		<el-input v-model="globalStyle.itemStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																		<el-select v-model="globalStyle.itemStyle.borderStyle" placeholder="请选择">
																			<el-option label="实线" value="solid"></el-option>
																			<el-option label="虚线" value="dashed"></el-option>
																			<el-option label="点线" value="dotted"></el-option>
																		</el-select>
																		<el-color-picker v-model="globalStyle.itemStyle.borderColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">圆角</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.itemStyle.borderRadius" :max="200"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="globalStyle.itemStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="itemPaddingFlag" size="mini">
																			<el-radio-button label="上下"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="itemPaddingFlag == '上下'">
																	<div class="sub_label">上下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.itemStyle.paddingVertical" :max="100"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="itemPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.itemStyle.paddingHorizontal" :max="100"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">间隔</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.itemStyle.interval" :max="100"></el-slider>
																	</div>
																</div>
															</div>
														</transition>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单项标题样式</span>
															</div>
															<div class="fold_btn" :class="{active: labelFoldFlag}" @click="labelFoldFlag = !labelFoldFlag">
																<span>{{labelFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="labelFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalStyle.labelStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalStyle.labelStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalStyle.labelStyle.fontWight == 'bold'}" @click="globalStyle.labelStyle.fontWight = globalStyle.labelStyle.fontWight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalStyle.labelStyle.fontStyle == 'italic'}" @click="globalStyle.labelStyle.fontStyle = globalStyle.labelStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalStyle.labelStyle.textDecoration == 'underline'}" @click="globalStyle.labelStyle.textDecoration = globalStyle.labelStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">对齐方式</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="globalStyle.labelStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">宽度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.labelStyle.width" :max="500"></el-slider>
																	</div>
																</div>
															</div>
														</transition>
													</div>
												</div>
											</div>
										</div>
									</div>
								</transition>
							</div>
						</div>
					</div>
					<div class="wrap question_types mobile"  v-show="equipment == 2">
						<div class="main_area">
							<div class="edit_cont">
								<div class="edit_wrap">
									<div class="equipment_switch">
										<div class="btn el-icon-monitor" :class="{'active': equipment == 1}" @click="equipment = 1"></div>
										<div class="btn el-icon-mobile" :class="{'active': equipment == 2}" @click="equipment = 2"></div>
									</div>
									<div class="edit_inner m_edit_inner">
										<div class="scroll_inner">
											<div class="mform-logo"></div>
											<div class="mform-head" v-show="globalProperties.formHeader.isShow">
												<div v-show="globalProperties.formHeader.type === '文字'" id="mFormHeadText" class="txt" key="mFormHeadText">{{globalProperties.formHeader.textCont}}</div>
												<div v-show="globalProperties.formHeader.type === '图片'" class="img" key="mFormHeadImg">
													<img v-if="globalProperties.formHeader.imgSrc" :style="{height: mGlobalStyle.formHeader.imgHeight + 'px'}" :src="globalProperties.formHeader.imgSrc">
												</div>
											</div>
											<div class="medit-head">
												<div class="mform-title" id="mFormTitle" v-show="globalProperties.formTitle.isShow">
													{{globalProperties.formTitle.textCont}}
												</div>
												<div class="mform-descript" id="mFormDescript" v-show="globalProperties.formDescript.isShow">{{globalProperties.formDescript.textCont}}</div>
											</div>
											<div class="mform-widget" :style="'padding: ' + mGlobalStyle.contStyle.paddingTop + 'px ' + mGlobalStyle.contStyle.paddingHorizontal + 'px ' + mGlobalStyle.contStyle.paddingBottom + 'px'">
												<div class="mform-list" v-if="list.length > 0">
													<div class="mform-item" v-for="(item,index) in list" :key="index">
														<div class="mform-sub" v-for="(o,i) in item.children" :key="i">
															<mWidget :detial="o" :cusStyle="mGlobalStyle" />		
															<div class="cover"></div>
														</div>
													</div>
												</div>
												<noData v-else text="暂时还没有表单控件哦！"></noData>
											</div>
											<div id="mSubmitBtn" class="submit_btn" v-show="list.length > 0">
												<el-button type="primary">{{globalStyle.submitBtnStyle.text}}</el-button>
											</div>
										</div>
									</div>
								</div>
								<div class="set_area">
									<div class="set_list">
										<div class="set_item">
											<div class="title">手机模式设置</div>
											<div class="list">
												<div class="item" v-show="globalProperties.formHeader.isShow">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">页眉</span>
														</div>
														<div class="fold_btn" :class="{active: mFormHeadFoldFlag}" @click="mFormHeadFoldFlag = !mFormHeadFoldFlag">
															<span>{{mFormHeadFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mFormHeadFoldFlag">
															<template v-if="globalProperties.formHeader.type === '文字'">
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="mGlobalStyle.formHeader.textStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="mGlobalStyle.formHeader.textStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.formHeader.textStyle.fontWight == 'bold'}" @click="mGlobalStyle.formHeader.textStyle.fontWight = mGlobalStyle.formHeader.textStyle.fontWight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.formHeader.textStyle.fontStyle == 'italic'}" @click="mGlobalStyle.formHeader.textStyle.fontStyle = mGlobalStyle.formHeader.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.formHeader.textStyle.textDecoration == 'underline'}" @click="mGlobalStyle.formHeader.textStyle.textDecoration = mGlobalStyle.formHeader.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">对齐方式</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="mGlobalStyle.formHeader.textStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景颜色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="mGlobalStyle.formHeader.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="mFormHeadTextPaddingFlag" size="mini">
																			<el-radio-button label="上下"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="mFormHeadTextPaddingFlag == '上下'">
																	<div class="sub_label">上下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="mGlobalStyle.formHeader.textStyle.paddingVertical" :max="100"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="mFormHeadTextPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="mGlobalStyle.formHeader.textStyle.paddingHorizontal" :max="100"></el-slider>
																	</div>
																</div>
															</template>
															<template v-else>													
																<div class="sub_item">
																	<div class="sub_label">图片高度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="mGlobalStyle.formHeader.imgHeight" :min="40" :max="500"></el-slider>
																	</div>
																</div>
															</template>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">背景</span>
														</div>
														<el-switch v-model="mGlobalStyle.formBg.isShow"></el-switch>
													</div>
													<div class="sub_list" v-show="mGlobalStyle.formBg.isShow">
														<div class="sub_item">
															<div class="sub_label">背景类型</div>
															<div class="sub_cont">
																<el-radio-group v-model="mGlobalStyle.formBg.type" size="mini">
																	<el-radio-button label="颜色"></el-radio-button>
																	<el-radio-button label="图片"></el-radio-button>
																</el-radio-group>
															</div>
														</div>
														<div class="sub_item" v-show="mGlobalStyle.formBg.type == '颜色'">
															<div class="sub_label">颜色</div>
															<div class="sub_cont">
																<el-color-picker v-model="mGlobalStyle.formBg.color" size="mini" show-alpha></el-color-picker>
															</div>
														</div>
														<div class="sub_item" v-show="mGlobalStyle.formBg.type == '图片'">
															<div class="sub_label">图片</div>
															<div class="sub_cont">
																<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" accept="image/*" :before-upload="beforeImgUpload" :on-success="handlemFormBgSuccess" :show-file-list="false">
																	<div v-if="mGlobalStyle.formBg.imgSrc" class="avatar">
																		<img :src="mGlobalStyle.formBg.imgSrc">
																		<div class="el-icon-error" @click.stop="mGlobalStyle.formBg.imgSrc = ''"></div>
																	</div>
																	<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																</el-upload>
															</div>
														</div>
													</div>
												</div>
												<div class="item" v-show="globalProperties.formTitle.isShow">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单标题</span>
														</div>
														<div class="fold_btn" :class="{active: mFormTitleFoldFlag}" @click="mFormTitleFoldFlag = !mFormTitleFoldFlag">
															<span>{{mFormTitleFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mFormTitleFoldFlag">
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.formTitle.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.formTitle.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.formTitle.textStyle.fontWight == 'bold'}" @click="mGlobalStyle.formTitle.textStyle.fontWight = mGlobalStyle.formTitle.textStyle.fontWight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.formTitle.textStyle.fontStyle == 'italic'}" @click="mGlobalStyle.formTitle.textStyle.fontStyle = mGlobalStyle.formTitle.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.formTitle.textStyle.textDecoration == 'underline'}" @click="mGlobalStyle.formTitle.textStyle.textDecoration = mGlobalStyle.formTitle.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mFormTitlePaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mFormTitlePaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formTitle.textStyle.paddingTop" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mFormTitlePaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formTitle.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mFormTitlePaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formTitle.textStyle.paddingBottom" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景设置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.formTitle.textStyle.bgType" size="mini">
																		<el-radio-button label="默认"></el-radio-button>
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mGlobalStyle.formTitle.textStyle.bgType == '颜色'">
																<div class="sub_label">选择颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.formTitle.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-show="mGlobalStyle.formTitle.textStyle.bgType == '图片'">
																<div class="sub_label">上传图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do" accept="image/*" :before-upload="beforeImgUpload" :on-success="handlemFormTitleSuccess" :show-file-list="false">
																		<div v-if="mGlobalStyle.formTitle.textStyle.backgroundImage" class="avatar">
																			<img :src="mGlobalStyle.formTitle.textStyle.backgroundImage">
																			<div class="el-icon-error" @click.stop="mGlobalStyle.formTitle.textStyle.backgroundImage = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item" v-show="globalProperties.formDescript.isShow">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单描述</span>
														</div>
														<div class="fold_btn" :class="{active: mFormDescriptFoldFlag}" @click="mFormDescriptFoldFlag = !mFormDescriptFoldFlag">
															<span>{{mFormDescriptFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mFormDescriptFoldFlag">
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.formDescript.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.formDescript.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.formDescript.textStyle.fontWight == 'bold'}" @click="mGlobalStyle.formDescript.textStyle.fontWight = mGlobalStyle.formDescript.textStyle.fontWight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.formDescript.textStyle.fontStyle == 'italic'}" @click="mGlobalStyle.formDescript.textStyle.fontStyle = mGlobalStyle.formDescript.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.formDescript.textStyle.textDecoration == 'underline'}" @click="mGlobalStyle.formDescript.textStyle.textDecoration = mGlobalStyle.formDescript.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mFormDescriptPaddingFlag" size="mini">
																		<el-radio-button label="上下"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mFormDescriptPaddingFlag == '上下'">
																<div class="sub_label">上下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formDescript.textStyle.paddingVertical" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mFormDescriptPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formDescript.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">内容区</span>
														</div>
														<div class="fold_btn" :class="{active: mContFoldFlag}" @click="mContFoldFlag = !mContFoldFlag">
															<span>{{mContFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mContFoldFlag">
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mContPaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mContPaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.contStyle.paddingTop"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mContPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.contStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mContPaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.contStyle.paddingBottom"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单项样式</span>
														</div>
														<div class="fold_btn" :class="{active: mItemFoldFlag}" @click="mItemFoldFlag = !mItemFoldFlag">
															<span>{{mItemFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mItemFoldFlag">
															<div class="sub_item">
																<div class="sub_label">边框</div>
																<div class="sub_cont border_style">
																	<el-input v-model="mGlobalStyle.itemStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																	<el-select v-model="mGlobalStyle.itemStyle.borderStyle" placeholder="请选择">
																		<el-option label="实线" value="solid"></el-option>
																		<el-option label="虚线" value="dashed"></el-option>
																		<el-option label="点线" value="dotted"></el-option>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.itemStyle.borderColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">圆角</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.itemStyle.borderRadius" :max="50"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.itemStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mItemPaddingFlag" size="mini">
																		<el-radio-button label="上下"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mItemPaddingFlag == '上下'">
																<div class="sub_label">上下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.itemStyle.paddingVertical" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mItemPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.itemStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">间隔</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.itemStyle.interval" :max="100"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单项标题样式</span>
														</div>
														<div class="fold_btn" :class="{active: mLabelFoldFlag}" @click="mLabelFoldFlag = !mLabelFoldFlag">
															<span>{{mLabelFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mLabelFoldFlag">
															<div class="sub_item">
																<div class="sub_label">标题位置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.labelStyle.site" size="mini">
																		<el-radio-button label="左侧"></el-radio-button>
																		<el-radio-button label="上方"></el-radio-button>
																		<el-radio-button label="右侧"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>												
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.labelStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.labelStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.labelStyle.fontWight == 'bold'}" @click="mGlobalStyle.labelStyle.fontWight = mGlobalStyle.labelStyle.fontWight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.labelStyle.fontStyle == 'italic'}" @click="mGlobalStyle.labelStyle.fontStyle = mGlobalStyle.labelStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.labelStyle.textDecoration == 'underline'}" @click="mGlobalStyle.labelStyle.textDecoration = mGlobalStyle.labelStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">对齐方式</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.labelStyle.textAlign" size="mini">
																		<el-radio-button label="居左"></el-radio-button>
																		<el-radio-button label="居中"></el-radio-button>
																		<el-radio-button label="居右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">宽度</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.labelStyle.width" :max="200"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">输入框</span>
														</div>
														<div class="fold_btn" :class="{active: mInputFoldFlag}" @click="mInputFoldFlag = !mInputFoldFlag">
															<span>{{mInputFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mInputFoldFlag">
															<div class="sub_item">
																<div class="sub_label">高度</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.inputStyle.height" :max="200" :min="20"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">边框</div>
																<div class="sub_cont border_style">
																	<el-input v-model="mGlobalStyle.inputStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																	<el-select v-model="mGlobalStyle.inputStyle.borderStyle" placeholder="请选择">
																		<el-option label="实线" value="solid"></el-option>
																		<el-option label="虚线" value="dashed"></el-option>
																		<el-option label="点线" value="dotted"></el-option>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.inputStyle.borderColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">圆角</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.inputStyle.borderRadius" :max="200"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.inputStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">提交按钮</span>
														</div>
														<div class="fold_btn" :class="{active: mSubmitBtnFoldFlag}" @click="mSubmitBtnFoldFlag = !mSubmitBtnFoldFlag">
															<span>{{mSubmitBtnFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mSubmitBtnFoldFlag">
															<div class="sub_item">
																<div class="sub_label">按钮宽度</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.width" :max="375" :min="40"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">按钮高度</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.height" :min="20"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">按钮位置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.submitBtnStyle.textAlign" size="mini">
																		<el-radio-button label="居左"></el-radio-button>
																		<el-radio-button label="居中"></el-radio-button>
																		<el-radio-button label="居右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.submitBtnStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.submitBtnStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.submitBtnStyle.fontWight == 'bold'}" @click="mGlobalStyle.submitBtnStyle.fontWight = mGlobalStyle.submitBtnStyle.fontWight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.submitBtnStyle.fontStyle == 'italic'}" @click="mGlobalStyle.submitBtnStyle.fontStyle = mGlobalStyle.submitBtnStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.submitBtnStyle.textDecoration == 'underline'}" @click="mGlobalStyle.submitBtnStyle.textDecoration = mGlobalStyle.submitBtnStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">边框</div>
																<div class="sub_cont border_style">
																	<el-input v-model="mGlobalStyle.submitBtnStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																	<el-select v-model="mGlobalStyle.submitBtnStyle.borderStyle" placeholder="请选择">
																		<el-option label="实线" value="solid"></el-option>
																		<el-option label="虚线" value="dashed"></el-option>
																		<el-option label="点线" value="dotted"></el-option>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.submitBtnStyle.borderColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">圆角</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.borderRadius" :max="50"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.submitBtnStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">外边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mSubmitBtnPaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mSubmitBtnPaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.paddingTop"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mSubmitBtnPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.paddingHorizontal" :max="500"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mSubmitBtnPaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.paddingBottom"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
import designHeader from "@/components/designHeader.vue";
import designLeftNav from "@/components/designLeftNav.vue";
import noData from "@/components/noData";
import ybdWidget from "@/components/ybdWidget";
import mWidget from "@/components/mWidget";
import widgetSet from "@/components/widgetSet";
import draggable from "vuedraggable";
import { get,post } from "/src/services/ajax_ucenter.js";
export default {
	name: "questionTypes",
	components: {
		designHeader,
		designLeftNav,
		noData,
		draggable,
		ybdWidget,
		mWidget,
		widgetSet,
	},
	data() {
		return {
			formToken:sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			identityType:sessionStorage.getItem('identityType')?sessionStorage.getItem('identityType'):'',
			fromType:'',
			formTypeId:'',
			formId: '',
			userId:'',
			useFlag:false,
			widgetGroupList:[],
			showBtn:true,
			hasEdit:0,
			isSaving:false,
			isJz: sessionStorage.getItem('isJz')?true:false,
			globalStyle: {
				themeColor: "#0cf",
				formWidth: 1100,
				contStyle:{
					paddingTop: 20,
					paddingHorizontal: 30,
					paddingBottom: 20,
				},
				itemStyle: {
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "#ddd",
					borderRadius: 0,
					backgroundColor: "#fff",
					paddingVertical: 10,
					paddingHorizontal: 10,
					interval: 15,
				},
				labelStyle: {
					width: 100,
					fontSize: 14,
					color: "#333",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					textAlign: "居左",
				},
				submitBtnStyle: {
					width: 100,
					height: 40,
					text: "提交",
					fontSize: 14,
					color: "#fff",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "",
					borderRadius: 4,
					backgroundColor: "",
					textAlign: "居中",
					paddingTop: 10,
					paddingHorizontal: 0,
					paddingBottom: 30,
				},
			},
			globalProperties: {
				formHeader: {
					isShow: false,
					type: "文字",
					textCont: "瑞蚁云表单",
					textStyle: {
						fontSize: 14,
						color: "#333",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						textAlign: "居左",
						backgroundColor: "#EFF4FE",
						paddingVertical: 10,
						paddingHorizontal: 20,
					},
					imgSrc: "",
					imgHeight: 300
				},
				formBg: {
					isShow: false,
					type: '颜色',
					color: '#fff',
					imgSrc: ''
				},
				formTitle: {
					isShow: true,
					textCont: "表单名称",
					textStyle: {
						fontSize: 20,
						color: "#333",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						bgType: "默认",
						backgroundColor: "#fff",
						backgroundImage: "",
						paddingTop: 30,
						paddingHorizontal: 30,
						paddingBottom: 30,
					},
				},
				formDescript: {
					isShow: false,
					textCont: "瑞蚁云表单：简洁易用的在线表单设计、数据管理工具,提供海量模板、即时统计、移动办公、团队协作等功能。",
					textStyle: {
						fontSize: 14,
						color: "#999",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						paddingVertical: 5,
						paddingHorizontal: 30,
					},
				},
			},
			mGlobalStyle: {
				formHeader: {
					textStyle: {
						fontSize: 14,
						color: "#333",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						textAlign: "居左",
						backgroundColor: "#EFF4FE",
						paddingVertical: 10,
						paddingHorizontal: 20,
					},
					imgHeight: 150
				},
				formBg: {
					isShow: false,
					type: '颜色',
					color: '#fff',
					imgSrc: ''
				},
				formTitle: {
					textStyle: {
						fontSize: 20,
						color: "#333",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						bgType: "默认",
						backgroundColor: "#fff",
						backgroundImage: "",
						paddingTop: 30,
						paddingHorizontal: 10,
						paddingBottom: 10,
					},
				},
				formDescript: {
					textStyle: {
						fontSize: 14,
						color: "#999",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						paddingVertical: 5,
						paddingHorizontal: 10,
					},
				},
				contStyle:{
					paddingTop: 10,
					paddingHorizontal: 10,
					paddingBottom: 10,
				},
				itemStyle: {
					site: '左侧',
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "#ddd",
					borderRadius: 0,
					backgroundColor: "#fff",
					paddingVertical: 10,
					paddingHorizontal: 10,
					interval: 15,
					labelSite: "left"
				},
				labelStyle: {
					width: 60,
					fontSize: 12,
					color: "#333",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					textAlign: "居左",
				},
				inputStyle: {
					height: 40,
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "#DCDFE6",
					borderRadius: 4,
					backgroundColor: "#fff",
				},
				submitBtnStyle: {
					width: 100,
					height: 40,
					fontSize: 14,
					color: "#fff",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "",
					borderRadius: 4,
					backgroundColor: "",
					textAlign: "居中",
					paddingTop: 10,
					paddingHorizontal: 0,
					paddingBottom: 30,
				},
			},
			list: [],
			// 临时数据 用于页面展示 不需要提交
			widgetList: [
				{
					id: 1,
					type: "input",
					name: "单行文本",
					label: "单行文本",
					showLabel: true,
					required: true,
					placeholder: "请输入内容",
					value: "",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 2,
					type: "textarea",
					name: "多行文本",
					label: "多行文本",
					showLabel: true,
					required: true,
					placeholder: "请输入内容",
					value: "",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						rows: 3,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 3,
					type: "radio",
					name: "单选框组",
					label: "单选框",
					showLabel: true,
					required: true,
					value: "",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					options: [
						{
							value: "选项一",
						},
						{
							value: "选项二",
						},
						{
							value: "选项三",
						},
					],
					inputStyle: {
						orientationMode: "横向",
						intervalVertical: 12,
						intervalHorizontal: 30,
					},
				},
				{
					id: 4,
					type: "checkbox",
					name: "复选框组",
					label: "复选框",
					showLabel: true,
					required: true,
					value: [],
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					options: [
						{
							value: "选项一",
						},
						{
							value: "选项二",
						},
						{
							value: "选项三",
						},
					],
					inputStyle: {
						orientationMode: "横向",
						intervalVertical: 12,
						intervalHorizontal: 30,
					},
				},
				{
					id: 5,
					type: "select",
					name: "下拉列表",
					label: "下拉列表",
					showLabel: true,
					required: true,
					value: "",
					placeholder: "请选择",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					options: [
						{
							value: "选项一",
						},
						{
							value: "选项二",
						},
						{
							value: "选项三",
						},
					],
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 6,
					type: "number",
					name: "数字输入",
					label: "数字",
					showLabel: true,
					required: true,
					placeholder: "请输入数字",
					value: "",
					allowDecimal: false,
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 7,
					type: "date",
					name: "日期时间",
					label: "日期时间",
					showLabel: true,
					required: true,
					placeholder: "请选择日期时间",
					startPlaceholder: "开始日期时间",
					endPlaceholder: "结束日期时间",
					value: "",
					isRang: false,
					dataType: "datetime",
					rangDataType: "datetimerange",
					queryItemVal: false,
					columnItemVal: false,
					isCurrent: false,
					valueFormat: "文字",
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						height: 40,
						iconSite: "居左",
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 8,
					type: "location",
					name: "省市联动",
					label: "省市联动",
					showLabel: true,
					required: true,
					placeholder: "请选择地区",
					queryItemVal: false,
					columnItemVal: false,
					itemStyle: {},
					labelStyle: {},
					value: '',
					inputStyle: {
						height: 40,
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#DCDFE6",
						borderRadius: 4,
						backgroundColor: "#fff",
					},
				},
				{
					id: 9,
					type: "table",
					name: "表格输入",
					label: "表格",
					showLabel: true,
					rowNum: 5,
					cols: [
						{ value: "字段1" },
						{ value: "字段2" },
						{ value: "字段3" },
						{ value: "字段4" },
						{ value: "字段5" },
					],
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						fontSize: 14,
						color: "#999",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						textAlign: "居中",
						backgroundColor: "#fff",
						borderColor: "#EBEEF5",
					},
				},
				{
					id: 10,
					type: "image",
					name: "图片上传",
					label: "图片上传",
					showLabel: true,
					required: true,
					allowNum: 1,
					allowSize: 2,
					itemStyle: {},
					labelStyle: {},
					value: [],
					inputStyle: {
							size: 140,
							text: "上传图片",
							fontSize: 14,
							color: "#999",
							fontWight: "normal",
							fontStyle: "normal",
							textDecoration: "none",
							borderWidth: 1,
							borderStyle: "dashed",
							borderColor: "#ddd",
							borderRadius: 4,
							backgroundColor: "#fff",
					},
				},
				{
					id: 11,
					type: "attachment",
					name: "附件上传",
					label: "附件上传",
					showLabel: true,
					required: true,
					allowNum: 1,
					allowSize: 2,
					value: [],
					itemStyle: {},
					labelStyle: {},
					inputStyle: {
						width: 120,
						height: 40,
						isIcon: true,
						text: "点击上传",
						fontSize: 14,
						color: "#fff",
						fontWight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "",
						borderRadius: 4,
						backgroundColor: "",
					},
				},
				{
					id: 12,
					type: "divider",
					name: "分割线",
					label: "分割线",
					showText: false,
					dividerText: "",
					fontSize: 14,
					color: "#999",
					fontWight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					dividerTextPosition: "居中",
					size: 1,
					dividerColor: "#DCDFE6",
					marginVertical: 24,
					marginHorizontal: 0,
				},
			],
			equipment: 1,
			tabNavCurrent: 0,
			setShow: true,
			headerImgTypeList: [],
			headerImgTypeId: "",
			headerImgList: [],
			formTitleTips: false,
			formDescriptTips: false,
			isDragging: false,
			currentWidget: '',
			dragTipsFlag: true,
			submitBtnFoldFlag: false,
			contFoldFlag: false,
			itemFoldFlag: false,
			labelFoldFlag: false,
			submitBtnPaddingFlag: "上",
			contPaddingFlag: "上",
			itemPaddingFlag: "上下",
			formHeadTextPaddingFlag: "上下",
			formTitlePaddingFlag: "上",
			formDescriptPaddingFlag: "上下",
			headerImageListShowFlag: false,
			mFormHeadFoldFlag: false,
			mFormHeadTextPaddingFlag: "上下",
			mFormTitleFoldFlag: false,
			mFormTitlePaddingFlag: "上",
			mFormDescriptFoldFlag: false,
			mFormDescriptPaddingFlag: "上下",
			mContFoldFlag: false,
			mContPaddingFlag: "上",
			mItemFoldFlag: false,
			mItemPaddingFlag: "上下",
			mLabelFoldFlag: false,
			mInputFoldFlag: false,
			mSubmitBtnFoldFlag: false,
			mSubmitBtnPaddingFlag: "上",
			watchFlag: 0
		};
	},
	computed:{
		watchObj(){
			const obj = {
				globalStyle: this.globalStyle,
				globalProperties: this.globalProperties,
				mGlobalStyle: this.mGlobalStyle,
				list: this.list
			}
			return obj;
		}
	},
	watch: {
		"globalStyle.itemStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.globalStyle.itemStyle;
					this.list.forEach((o) => {
						if(o.children){
							o.children.forEach((m) => {
								m.itemStyle = {...styleStr};
							});
						}
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.labelStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.globalStyle.labelStyle;
					this.list.forEach((o) => {
						if(o.children){
							o.children.forEach((m) => {
								m.labelStyle = {...styleStr};
							});
						}
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.themeColor": {
			handler() {
				document.documentElement.style.setProperty("--theme-custom-color",this.globalStyle.themeColor);
			},
		},
		"globalStyle.submitBtnStyle": {
			handler() {
				const styleObj = this.globalStyle.submitBtnStyle;
				$("#submitBtn .el-button").css({
					width: styleObj.width + "px",
					height: styleObj.height + "px",
					borderWidth: styleObj.borderWidth + "px",
					borderStyle: styleObj.borderStyle,
					borderColor: styleObj.borderColor,
					borderRadius: styleObj.borderRadius + "px",
					backgroundColor: styleObj.backgroundColor,
					fontSize: styleObj.fontSize + "px",
					fontWeight: styleObj.fontWight,
					fontStyle: styleObj.fontStyle,
					color: styleObj.color,
					textDecoration: styleObj.textDecoration,
				});
				$("#submitBtn").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
					justifyContent: styleObj.textAlign == '居左'?'flex-start':styleObj.textAlign == '居中'?'center':'flex-end',
				});
				if(styleObj.text == ''){
					styleObj.isError = true;
				}else{
					styleObj.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalProperties.formHeader": {
			handler() {
				const formHeader = this.globalProperties.formHeader;
				if(!(formHeader.isShow && formHeader.type == '图片')){
					this.headerImageListShowFlag = false;
				}
				this.$nextTick(function () {
					let styleObj = this.globalProperties.formHeader.textStyle;
					$("#formHeadText").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						textAlign: styleObj.textAlign == "居左"? "left":styleObj.textAlign == "居中"?"center":"right",
						backgroundColor: styleObj.backgroundColor,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
				});
				if(formHeader.isShow && formHeader.type == '文字' && formHeader.textCont == ''){
					formHeader.isError = true;
				}else{
					formHeader.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalProperties.formTitle": {
			handler() {
				const formTitle = this.globalProperties.formTitle;
				this.$nextTick(function () {
					let styleObj = this.globalProperties.formTitle.textStyle;
					let backgroundStr = styleObj.bgType == "默认"? "unset":styleObj.bgType == "颜色"? styleObj.backgroundColor:"url(" + styleObj.backgroundImage + ")";
					$("#formTitle .el-textarea__inner").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
						background: backgroundStr,
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					});
					this.$refs.titleText.resizeTextarea();
				});
				if(formTitle.isShow && formTitle.textCont == ''){
					formTitle.isError = true;
				}else{
					formTitle.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalProperties.formDescript": {
			handler() {
				const formDescript = this.globalProperties.formDescript;
				this.$nextTick(function () {
					let styleObj = this.globalProperties.formDescript.textStyle;
					$("#formDescript .el-textarea__inner").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
					this.$refs.descriptText.resizeTextarea(); 
				});	
				if(formDescript.isShow && formDescript.textCont == ''){
					formDescript.isError = true;
				}else{
					formDescript.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.contStyle": {
			handler() {
				const styleObj = this.globalStyle.contStyle;
				$(".parent-wrap").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
				});
			},
			deep: true,
			immediate: true,
		},
		"globalProperties.formBg": {
			handler() {
				const styleObj = this.globalProperties.formBg;
				let backgroundStr = '';
				if(styleObj.isShow){
					if(styleObj.type == '颜色'){
						backgroundStr = styleObj.color;
					}else{
						if(styleObj.imgSrc){
							backgroundStr = 'url(' + styleObj.imgSrc + ')';
						}
					}
				}else{
					backgroundStr = '#fff';
				}
				$(".edit_inner:not(.m_edit_inner)").css({
					background: backgroundStr,
					backgroundSize: 'cover'
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formHeader": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formHeader.textStyle;
					$("#mFormHeadText").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						textAlign: styleObj.textAlign == "居左"? "left":styleObj.textAlign == "居中"?"center":"right",
						backgroundColor: styleObj.backgroundColor,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formTitle": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formTitle.textStyle;
					let backgroundStr = styleObj.bgType == "默认"? "unset":styleObj.bgType == "颜色"? styleObj.backgroundColor:"url(" + styleObj.backgroundImage + ")";
					$("#mFormTitle").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
						background: backgroundStr,
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formDescript": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formDescript.textStyle;
					$("#mFormDescript").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
				});	
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.contStyle": {
			handler() {
				const styleObj = this.mGlobalStyle.contStyle;
				$(".mform-widget").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formBg": {
			handler() {
				const styleObj = this.mGlobalStyle.formBg;
				let backgroundStr = '';
				if(styleObj.isShow){
					if(styleObj.type == '颜色'){
						backgroundStr = styleObj.color;
					}else{
						if(styleObj.imgSrc){
							backgroundStr = 'url(' + styleObj.imgSrc + ')';
						}
					}
				}else{
					backgroundStr = '#fff';
				}
				$(".m_edit_inner .scroll_inner").css({
					background: backgroundStr,
					backgroundSize: 'cover'
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.submitBtnStyle": {
			handler() {
				const styleObj = this.mGlobalStyle.submitBtnStyle;
				$("#mSubmitBtn .el-button").css({
					width: styleObj.width + "px",
					height: styleObj.height + "px",
					borderWidth: styleObj.borderWidth + "px",
					borderStyle: styleObj.borderStyle,
					borderColor: styleObj.borderColor,
					borderRadius: styleObj.borderRadius + "px",
					backgroundColor: styleObj.backgroundColor,
					fontSize: styleObj.fontSize + "px",
					fontWeight: styleObj.fontWight,
					fontStyle: styleObj.fontStyle,
					color: styleObj.color,
					textDecoration: styleObj.textDecoration,
				});
				$("#mSubmitBtn").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
					justifyContent: styleObj.textAlign == '居左'?'flex-start':styleObj.textAlign == '居中'?'center':'flex-end',
				});
			},
			deep: true,
			immediate: true,
		},
		"headerImgTypeId": {
			handler() {
				this.getHeaderImgList();
			},
			deep: true,
			immediate: true,
		},
		watchFlag(val){
			this.hasEdit = val;
		}
	},
	created(){
		const {authToken,identityType,formId} = this.$route.query;
		this.identityType = identityType?identityType:sessionStorage.getItem('identityType');
		this.formId = formId?formId:sessionStorage.getItem('formId');
		this.fromType = this.$route.query.fromType?this.$route.query.fromType:sessionStorage.getItem('fromType')?sessionStorage.getItem('fromType'):'';
		this.formTypeId = this.$route.query.formTypeId?this.$route.query.formTypeId:sessionStorage.getItem('formTypeId')?sessionStorage.getItem('formTypeId'):'';
		sessionStorage.setItem('fromType',this.fromType);
		sessionStorage.setItem('formTypeId',this.formTypeId);
		sessionStorage.setItem('identityType',this.identityType);
		if(!this.formToken && identityType == 'user'){
			// 校验登录状态
			if(authToken && identityType){
				this.auth(authToken,identityType);
				sessionStorage.setItem('identityType',identityType);
			}else{
				this.$router.push('/login')
			}
		}else{
			if(this.identityType == 'admin'){
				this.getFormTpl();
				sessionStorage.setItem('formId',this.formId)
			}else{
				this.userId = this.$route.query.userId?this.$route.query.userId:'';
				if(this.userId){
					this.useFlag = true;
					this.getTplFormEdit(this.formId,this.userId)
				}else if(this.formId){
					this.getFormDetail(this.formId);
					sessionStorage.setItem('formId',this.formId)
				}else{
					this.getWidgets(this.formTypeId);
				}
			}
		}
	},
	methods: {
		// 登录
		auth(authToken,identityType){
			const _this = this;
			post('/com/auth.api',{authToken,identityType}).then(res => {
				if(res.code == 200){
					sessionStorage.setItem('token',res.data.form_token);
					_this.formToken = res.data.form_token;
					//location.reload();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 编辑表单先暂停发布
		stopPublish(formId){
			const _this = this;
			this.$confirm('修改后您收集的关于该表单的所有数据将背删除！确定继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/stop_publish.jhtml',{formId}).then(res => {
					if(res.code == 200){
						_this.$message.success('暂停发布成功！');
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				this.$router.back()
				// return false;
			});
		},
		changeWidgetGroup(list){
			const _this = this;
			_this.widgetList = [];
			list.forEach(widgetGroup=>{
				let group = [];
				widgetGroup.question.forEach(widget=>{
					let item = {};
					item = {
						...widget,
						itemStyle: _this.globalStyle.itemStyle,
						labelStyle: _this.globalStyle.labelStyle,
					}
					item.showLabel = true;
					if(item.topic_required == '1' || item.topic_required == 'true'){
						item.topic_required = true;
					}else{
						item.topic_required = false;
					}
					item.topicOption = item.topicOption && JSON.parse(item.topicOption);
					if(item.modelType == 'lc-input'||item.modelType == 'lc-select'||item.modelType == 'lc-number'||item.modelType == 'lc-location'){
						item = {
							...item,
							allowDecimal: false,
							inputStyle:{
								height: 40,
								borderWidth: 1,
								borderStyle: "solid",
								borderColor: "#DCDFE6",
								borderRadius: 4,
								backgroundColor: "#fff",
								placeholder:item.remarks
							},
							value:''
						}
					}else if(item.modelType == 'lc-textarea'){
						item = {
							...item,
							inputStyle:{
								rows: 3,
								borderWidth: 1,
								borderStyle: "solid",
								borderColor: "#DCDFE6",
								borderRadius: 4,
								backgroundColor: "#fff",
							},
							value:''
						}
					}else if(item.modelType == 'lc-radio' || item.modelType == 'lc-checkbox'){
						item = {
							...item,
							inputStyle:{
								orientationMode: "横向",
								intervalVertical: 12,
								intervalHorizontal: 30,
							},
						}
						if(item.modelType == 'lc-radio'){
							item = {
								...item,
								value:''
							}
						}else{
							item = {
								...item,
								value:[]
							}
						}
					}else if(item.modelType == 'lc-date'){
						item = {
							...item,
							valueFormat: "文字",
							inputStyle:{
								height: 40,
								iconSite: "居左",
								borderWidth: 1,
								borderStyle: "solid",
								borderColor: "#DCDFE6",
								borderRadius: 4,
								backgroundColor: "#fff",
							},
							value:''
						}
					}else if(item.modelType == 'lc-table'){
						item = {
							...item,
							rowNum: 5,
							inputStyle:{
								fontSize: 14,
								color: "#999",
								fontWight: "normal",
								fontStyle: "normal",
								textDecoration: "none",
								textAlign: "居中",
								backgroundColor: "#fff",
								borderColor: "#EBEEF5",
							},
							value:[]
						}
					}else if(item.modelType == 'lc-image'){
						item = {
							...item,
							allowNum: 1,
							allowSize: 2,
							inputStyle:{
								size: 140,
								fontSize: 14,
								color: "#999",
								fontWight: "normal",
								fontStyle: "normal",
								textDecoration: "none",
								borderWidth: 1,
								borderStyle: "dashed",
								borderColor: "#ddd",
								borderRadius: 4,
								backgroundColor: "#fff",
								text:item.remarks
							},
							value:[]
						}
					}else if(item.modelType == 'lc-attachment'){
						item = {
							...item,
							allowNum: 1,
							allowSize: 2,
							inputStyle:{
								width: 120,
								height: 40,
								isIcon: true,
								text: item.remarks,
								fontSize: 14,
								color: "#fff",
								fontWight: "normal",
								fontStyle: "normal",
								textDecoration: "none",
								borderWidth: 1,
								borderStyle: "solid",
								borderColor: "",
								borderRadius: 4,
								backgroundColor: "",
							},
							value:[]
						}
					}else if(item.modelType == 'lc-divider'){
						item = {
							...item,
							textDecoration: "none",
							dividerTextPosition: "居中",
							size: 1,
							dividerColor: "#DCDFE6",
							marginVertical: 24,
							marginHorizontal: 0,
						}
					}
					group.push(item);
					if(item.modelType != 'text' && item.modelType != 'hr'){
						_this.widgetList.push(item)	;	
					}
				})
				widgetGroup.question = group;
			})
			console.log(5555555,_this.widgetGroupList);
		},
		// 获取可用控件列表
		getWidgets(formTypeId){
			const _this = this;
			get('/formadmin/formmodel/from_add.jhtml',{formTypeId}).then(res => {
				if(res.code == 200){
					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.fromType = res.data.formTypeString;
					sessionStorage.setItem('fromType',res.data.formTypeString);
					_this.changeWidgetGroup(_this.widgetGroupList)
					setTimeout(() => {
						_this.$watch('watchObj', function(){
							this.watchFlag = 1;
						}, {deep: true})
					}, 10);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取表单详情
		getFormDetail(formId){
			const _this = this;
			get('/formadmin/formmodel/from_edit.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader?_this.globalStyle.formHeader:_this.globalProperties.formHeader,
						formBg:_this.globalStyle.formBg?_this.globalStyle.formBg:_this.globalProperties.formBg,
						formTitle:_this.globalStyle.formTitle?_this.globalStyle.formTitle:_this.globalProperties.formTitle,
						formDescript:_this.globalStyle.formDescript?_this.globalStyle.formDescript:_this.globalProperties.formDescript,
					};
					console.log(formObj);
					if(formObj.status && formObj.status == 2 && sessionStorage.getItem('identityType') !="user"){
						_this.stopPublish(_this.formId);
					}
					_this.fromType = formObj.fromType;
					_this.formTypeId = formObj.formTypeId;
					sessionStorage.setItem('fromType',formObj.fromType);
					sessionStorage.setItem('formTypeId',formObj.formTypeId);

					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.changeWidgetGroup(_this.widgetGroupList)
					_this.getFormStyleDetail(formId);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取表单风格详情
		getFormStyleDetail(formId){
			const _this = this;
			get('/formadmin/formmodel/from_add_style.jhtml',{formId}).then(res => {
				if(res.code == 200){
					_this.headerImgTypeList = res.data.typeList;
					_this.headerImgList = res.data.imgList;
					setTimeout(() => {
						_this.$watch('watchObj', function(){
							this.watchFlag = 1;
						}, {deep: true})
					}, 10);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板中心表单详情
		getFormTpl(){
			const _this = this;
			let param = {
				formId:_this.formId,
				fromType:_this.fromType,
				// formTypeId:_this.formTypeId
			}
			get('/superadm/formmodel/loadFormModelData.do',param).then(res => {
				if(res.code == 200){
					let formObj = res.data.formData;
					_this.list = formObj.list?formObj.list:[];
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):this.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):this.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader?_this.globalStyle.formHeader:_this.globalProperties.formHeader,
						formBg:_this.globalStyle.formBg?_this.globalStyle.formBg:_this.globalProperties.formBg,
						formTitle:_this.globalStyle.formTitle?_this.globalStyle.formTitle:_this.globalProperties.formTitle,
						formDescript:_this.globalStyle.formDescript?_this.globalStyle.formDescript:_this.globalProperties.formDescript,
					};
					_this.formTypeId = formObj.formTypeId;
					sessionStorage.setItem('formTypeId',formObj.formTypeId)
					_this.headerImgTypeList = res.data.imgType;
					_this.headerImgList = res.data.imgList;
					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.changeWidgetGroup(_this.widgetGroupList)
					setTimeout(() => {
						_this.$watch('watchObj', function(){
							this.watchFlag = 1;
						}, {deep: true})
					}, 10);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 模板中心选用获取模板表单数据
		getTplFormEdit(formId){
			const _this = this;
			post('/formadmin/quote_form.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);
					console.log(formObj,33)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader?_this.globalStyle.formHeader:_this.globalProperties.formHeader,
						formBg:_this.globalStyle.formBg?_this.globalStyle.formBg:_this.globalProperties.formBg,
						formTitle:_this.globalStyle.formTitle?_this.globalStyle.formTitle:_this.globalProperties.formTitle,
						formDescript:_this.globalStyle.formDescript?_this.globalStyle.formDescript:_this.globalProperties.formDescript,
					};
					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.fromType = res.data.formTypeString;
					sessionStorage.setItem('fromType',res.data.formTypeString)
					_this.formTypeId = formObj.formTypeId;
					sessionStorage.setItem('formTypeId',formObj.formTypeId)
					_this.changeWidgetGroup(_this.widgetGroupList)
					setTimeout(() => {
						_this.$watch('watchObj', function(){
							this.watchFlag = 1;
						}, {deep: true})
					}, 10);
					
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 从左侧拖拽出 过程
		dragMove(e) {
			if(e.to._prevClass == "list widget-list" || e.to._prevClass == "children-list full") return false;
			this.dragTipsFlag = false;
			this.isDragging = true;
		},
		// 从左侧拖拽出 结束
		dragEnd(e) {
			this.isDragging = false;
			let item = {
				children: [
					JSON.parse(
						JSON.stringify({
							...e.item._underlying_vm_,
							uuid: this.getUuid(),
						})
					),
				],
			};
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
			} else if (e.to._prevClass == "children-list full") {
				this.list[$(e.to).attr("index")].children.splice(e.newDraggableIndex, 1, item.children[0]);
			}
			if (this.list.length == 0) {
				this.dragTipsFlag = true;
			}
		},
		// 右侧拖拽 过程
		childSortMove(e) {
			this.isDragging = true;
			if (e.to._prevClass == "list widget-list" || (e.to._prevClass == "children-list full" && e.to != e.from)) return false;
		},
		// 右侧拖拽完成
		childSortEnd(e) {
			this.isDragging = false;
			let item = {
				children: [e.item._underlying_vm_],
			};
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
				this.list.forEach((o, i) => {
					if (o.children.length == 0) {
						this.list.splice(i, 1);
					}
				});
			}
		},
		// 控件生成唯一标识
		getUuid() {
			let s = [];
			let hexDigits = "0123456789abcdef";
			for (let i = 0; i < 36; i++) {
				s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
			}
			s[14] = "4";
			s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
			s[8] = s[13] = s[18] = s[23] = "-";
			return s.join("");
		},
		// 复制控件
		copyWidget(index, i) {
			let item = {
				children: [
					JSON.parse(
						JSON.stringify({
							...this.list[index].children[i],
							uuid: this.getUuid(),
						})
					),
				],
			};
			this.list.splice(index + 1, 0, item);
		},
		// 删除控件
		deleteWidget(index, i) {
			if (this.list[index].children.length == 1) {
				this.list.splice(index, 1);
			} else {
				this.list[index].children.splice(i, 1);
			}
			if (this.list.length == 0) {
				this.dragTipsFlag = true;
			}
		},
		// 上传前校验文件大小 不能超过5M
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < 5;
			if (!isLt5M) {
				this.$message.error("大小不能超过 5MB!");
			}
			return isLt5M;
		},
		// 上传页眉成功
		handleHeaderImgSuccess(file){
			if(file.code == 200){
				this.globalProperties.formHeader.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传表单背景图成功
		handleFormBgSuccess(file){
			if(file.code == 200){
				this.globalProperties.formBg.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传手机表单背景图成功
		handlemFormBgSuccess(file){
			if(file.code == 200){
				this.mGlobalStyle.formBg.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传表单标题背景图成功
		handleFormTitleSuccess(file){
			if(file.code == 200){
				this.globalProperties.formTitle.textStyle.backgroundImage = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传手机表单标题背景图成功
		handlemFormTitleSuccess(file){
			if(file.code == 200){
				this.mGlobalStyle.formTitle.textStyle.backgroundImage = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 获取页眉数据
		getHeaderImgList(){
			const _this = this,
			imgType = _this.headerImgTypeId;console.log(_this.headerImgTypeId)
			get('/formadmin/superAdmTypeImgs.jhtml',{imgType}).then(res => {
				if(res.code == 200){
					_this.headerImgList = res.data.imgList;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 保存，预览表单
		saveForm(preview,to){
			const _this = this,
			formHeader = _this.globalProperties.formHeader,
			formBg = _this.globalProperties.formBg,
			formTitle = _this.globalProperties.formTitle,
			formDescript = _this.globalProperties.formDescript,
			submitBtnStyle = _this.globalStyle.submitBtnStyle;
			// 校验页眉
			if(formHeader.isShow && formHeader.type == '文字' && formHeader.textCont == ''){
				this.$message.error('请输入页眉文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}else if(formHeader.isShow && formHeader.type == '图片' && formHeader.imgSrc == ''){
				this.$message.error('请选择页眉图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验背景图
			if(formBg.isShow && formBg.type == '图片' && formBg.imgSrc == ''){
				this.$message.error('请上传背景图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单标题
			if(formTitle.isShow && formTitle.textCont == ''){
				this.$message.error('请输入表单标题！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单描述
			if(formDescript.isShow && formDescript.textCont == ''){
				this.$message.error('请输入表单描述！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单提交按钮
			if(submitBtnStyle.text == ''){
				this.$message.error('请输入按钮文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			if(_this.list.length == 0){
				if(!(to && (to.path =='/questionBank' || to.path =='/template'))){
					this.equipment = 1;
					_this.$message.error('请拖入表单控件！');
					return false;
				}
				
			}
			_this.isSaving = true;
			_this.globalStyle ={
				..._this.globalStyle,
				..._this.globalProperties
			}
			let jsonDate = {
				pcStyle: JSON.stringify(_this.globalStyle),
				mobileStyle:JSON.stringify( _this.mGlobalStyle),
				list: _this.list,
				fromType: _this.formType,
				formTypeId: _this.formTypeId,
				type_id: sessionStorage.getItem('type_id')?sessionStorage.getItem('type_id'):'',
				title: _this.globalProperties.formTitle.textCont
			};
			let url = '/formadmin/formmodel/from_save.jhtml';
			let param = {jsonDate:JSON.stringify(jsonDate)}
			if(_this.identityType == 'admin'){
				jsonDate._id = _this.formId;
				url = '/superadm/formmodel/saveFormModelData.do';
				param = {jsonData:JSON.stringify(jsonDate)}
			}else if(_this.userId){
				url = '/formadmin/formmodel/from_save.jhtml';
				param = {jsonDate:JSON.stringify(jsonDate)}
			}else{
				if(_this.formId){
					// 编辑提交
					jsonDate._id = _this.formId;
					url = '/formadmin/formmodel/from_update.jhtml';
				}
				param = {jsonDate:JSON.stringify(jsonDate)}
			}
			post(url,param).then(res => {
				if(res.code == 200){
					// _this.isSaving = false;
					if(res.data.formId){
						_this.formId = res.data.formId;
						sessionStorage.setItem('formId',_this.formId);
					}
					if(_this.formId){
						_this.saveStyleForm(preview,to);
					}
					// _this.saveFormStyle(preview,to);
					// _this.$message({
					// 	type: 'success',
					// 	center: true,
					// 	duration: 1000,
					// 	message: "保存成功！",
					// 	onClose(){
					// 		_this.watchFlag = 0;
					// 		if(res.data.formId){
					// 			_this.formId = res.data.formId;
					// 			sessionStorage.setItem('formId',_this.formId);
					// 			if(_this.useFlag){
					// 				_this.useFlag = false;
					// 				if(to){
					// 					_this.$router.push(to.fullPath);
					// 				}else{
					// 					_this.$router.push({path:'/questionTypes'});
					// 				}
					// 			}
					// 		}
					// 		if(preview){
					// 			_this.$router.push({path:'/preview',query:{formId: _this.formId}});
					// 		}else if(sessionStorage.getItem('referrer')){
					// 			sessionStorage.setItem('formId','');
					// 		}
					// 	}
					// })
				}else{
						_this.$message.error(res.message);
						_this.isSaving = false;
						_this.watchFlag = 1;
					}
			}).catch(err => {
				_this.$message.error(err);
				_this.isSaving = false;
				_this.watchFlag = 1;
			})
			
		},	
		// 保存表单样式，预览表单
		saveStyleForm(preview,to){
			const _this = this,
			formHeader = _this.globalProperties.formHeader,
			formBg = _this.globalProperties.formBg,
			formTitle = _this.globalProperties.formTitle,
			formDescript = _this.globalProperties.formDescript,
			submitBtnStyle = _this.globalStyle.submitBtnStyle;
			// 校验页眉
			if(formHeader.isShow && formHeader.type == '文字' && formHeader.textCont == ''){
				this.$message.error('请输入页眉文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}else if(formHeader.isShow && formHeader.type == '图片' && formHeader.imgSrc == ''){
				this.$message.error('请选择页眉图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验背景图
			if(formBg.isShow && formBg.type == '图片' && formBg.imgSrc == ''){
				this.$message.error('请上传背景图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单标题
			if(formTitle.isShow && formTitle.textCont == ''){
				this.$message.error('请输入表单标题！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单描述
			if(formDescript.isShow && formDescript.textCont == ''){
				this.$message.error('请输入表单描述！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单提交按钮
			if(submitBtnStyle.text == ''){
				this.$message.error('请输入按钮文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// _this.isSaving = true;
			const globalStyle ={
				..._this.globalStyle,
				..._this.globalProperties
			}
			let jsonData = {
					pcStyle:JSON.stringify(globalStyle),
					mobileStyle: JSON.stringify(_this.mGlobalStyle),
					formId: _this.formId
				};
			let url = '/formadmin/formmodel/saveFrontFormStyle.jhtml';
			if(_this.identityType == 'admin'){
				let param = {
					pcStyle: JSON.stringify(_this.globalStyle),
					mobileStyle:JSON.stringify( _this.mGlobalStyle),
					formId: _this.formId,
					_id : _this.formId,
				}
				url = '/superadm/formmodel/saveFormModelData.do';
				jsonData = {jsonData:JSON.stringify(param)}
			}
			post(url,jsonData).then(res => {
				if(res.code == 200){
					_this.isSaving = false;
					if(to){
						_this.watchFlag = 0;
						if(_this.useFlag){
							_this.useFlag = false;
						}
						_this.$router.push(to.fullPath);
						if(preview){
							_this.$router.push({path:'/preview',query:{formId: _this.formId}});
						}else if(sessionStorage.getItem('referrer')){
							sessionStorage.setItem('formId','');
						}
					}else{
						_this.$message({
							type: 'success',
							center: true,
							duration: 1000,
							message: "保存成功！",
							onClose(){
								_this.watchFlag = 0;
								if(_this.useFlag){
									_this.useFlag = false;
									_this.$router.push({path:'/questionTypes'});
								}
								if(preview){
									_this.$router.push({path:'/preview',query:{formId: _this.formId}});
								}else if(sessionStorage.getItem('referrer')){
									sessionStorage.setItem('formId','');
									// window.location.href = sessionStorage.getItem('referrer') + '/formCloseBack.html'
								}
							}
						})
					}
				}
			}).catch(err => {
				_this.$message.error(err);
				_this.isSaving = false;
				_this.watchFlag = 1;
			})
		},	
	},
	beforeRouteLeave(to,from,next){
		if(this.watchFlag == 1){
			this.saveForm(false,to)
		}else{
			if(this.useFlag){
				this.saveForm(false,to)
			}else if(!this.formId){
				this.saveForm(false,to)
			}else{
				next();
			}
		}
	
	}
};
</script>
<style lang="less" scoped>
.full-wrap{
	width: 100%;
	height: 100%;
	.question_types {
		display: flex;
		width: 100%;
		height: 100%;
		.tab_wrap {
			display: flex;
			flex-direction: column;
			width: 290px;
			height: 100%;
			border-right: 1px solid #ddd;
			background-color: #f5f5f5;
			position: relative;
			.nav {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ddd;
				.item {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 50px;
					font-size: 15px;
					cursor: pointer;
				}
				.line {
					width: 1px;
					height: 30px;
					background-color: #ddd;
				}
				.active {
					background-color: #fff;
					color: var(--theme-color);
				}
			}
			.tab_list {
				flex: 1;
				overflow: hidden;
				.tab_item {
					height: 100%;
					padding: 20px 30px 10px;
					overflow-y: auto;
				}
				.widget {
					.title {
						margin: 0 0 10px;
						font-weight: bold;
					}
					.list {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						.item {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 48%;
							height: 36px;
							border: 1px solid #ddd;
							margin: 0 0 10px;
							border-radius: 2px;
							background-color: #fff;
							font-size: 12px;
							cursor: pointer;
							user-select: none;
							&:hover{
								box-shadow: 0 0 10px rgba(0,0,0,.1);
							}
						}
					}
				}
				.catalogue {
					.list {
						.item {
							display: flex;
							margin: 0 0 10px;
							a {
								flex: 1;
								height: 34px;
								line-height: 34px;
								border: 1px solid #ddd;
								border-radius: 3px;
								background-color: #fff;
								text-align: center;
								padding: 0 10px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 12px;
								color: #666;
								&:hover {
										color: var(--theme-color);
								}
							}
							a + a {
								border-left: 1px solid #ddd;
								margin: 0 0 0 10px;
							}
							a.active {
								border-color: var(--theme-color);
								background-color: var(--theme-color);
								color: #fff;
							}
						}
					}
				}
			}
			.header_image{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				z-index: 9;
				.main{
					display: flex;
					flex-direction: column;
					width: 290px;
					height: 100%;
					background-color: #fff;
					.head{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 50px;
						padding: 0 15px;
						border-bottom: 1px solid #ddd;
						font-size: 16px;
						.iconfont{
							margin: 10px 0 0;
							color: #999;
							font-size: 18px;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
					}
					.style_nav{
						display: flex;
						padding: 10px 15px 0;
						.label{
							color: #999;
						}
						.list{
							flex: 1;
							li{
								float: left;
								padding: 2px 7px;
								margin: 0 0 10px 6px;
								font-size: 12px;
								border: 1px solid #ddd;
								border-radius: 3px;
								color: #666;
								cursor: pointer;
								&:hover{
									border-color: var(--theme-color);
									color: var(--theme-color);
								}
							}
							li.active{
								background-color: var(--theme-color);
								border-color: var(--theme-color);
								color: #fff;
							}
						}
					}
					.img_list{
						flex: 1;
						padding: 10px 10px 0;
						overflow: hidden;
						::v-deep .el-scrollbar{
							height: 100%;
							.el-scrollbar__wrap{
								overflow-x: hidden;
								.el-scrollbar__view{
									display: flex;
									flex-wrap: wrap;
									.item{
										display: flex;
										align-items: center;
										justify-content: center;
										width: 124px;
										height: 40px;
										padding: 2px;
										border: 1px solid #ddd;
										border-radius: 3px;
										margin: 0 5px 10px;
										cursor: pointer;
										position: relative;
										overflow: hidden;
										&:hover{
											border-color: var(--theme-color);
										}
										.bat{
											display: none;
											width: 50px;
											height: 30px;
											line-height: 44px;
											text-align: center;
											background-color: var(--theme-color);
											transform: rotate(45deg);
											position: absolute;
											top: -13px;
											right: -23px;
											z-index: 9;
											.el-icon-check{
												color: #fff;
												font-size: 12px;
												font-weight: bold;
												transform: rotate(-33deg);
											}
										}
										.img{
											width: 100%;
											height: 100%;
											object-fit: contain;
										}
									}
									.item.active{
										border-color: var(--theme-color);
										.bat{
											display: block;
										}
									}
								}
							}							
						}
						::v-deep .item.upload{
							display: flex;
							align-items: center;
							justify-content: center;
							height: 40px;
							padding: 2px;
							border: 1px dashed #ddd;
							border-radius: 3px;
							margin: 0 5px 10px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
							.avatar-uploader{
								width: 100%;
								height: 100%;
								.el-upload{
									display: flex;
									align-items: center;
									justify-content: center;
									width: 100%;
									height: 100%;
									font-size: 12px;
									color: #999;
									.avatar-uploader-icon{
										display: block;
										margin: 0 5px 0 0;
										font-size: 18px;
										color: #aaa;
									}
								}
							}
							&:hover{
								border-color: var(--theme-color);
								.el-upload{
									color: var(--theme-color);
									.avatar-uploader-icon{
										color: var(--theme-color);
									}
								}
							}
						}
					}
				}
			}
		}
		.main_area {
			flex: 1;
			overflow: hidden;
			.edit_cont {
				display: flex;
				height: 100%;
				.edit_wrap{
					flex: 1;
					padding: 50px 20px 20px;
					position: relative;
					overflow-x: auto;
					.equipment_switch{
						display: flex;
						width: 90px;
						height: 34px;
						position: absolute;
						right: 20px;
						top: 8px;
						.btn{
							flex: 1;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px solid #ddd;
							background-color: #fff;
							font-size: 22px;
							color: #999;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
						.el-icon-monitor{
							border-right: none;
							border-radius: 3px 0 0 3px;
						}
						.el-icon-mobile{
							border-left: none;
							border-radius: 0 3px 3px 0;
						}
						.btn.active{
							background-color: var(--theme-color);
							border-color: var(--theme-color);
							color: #fff;
						}
					}
					.edit_inner {
						display: flex;
						flex-direction: column;
						width: 1100px;
						height: 100%;
						margin: 0 auto;
						background-color: #fff;
						overflow-y: auto;
						overflow-x: hidden;
						.form-head{
							.img{
								background-color: #f5f5f5;
								img{
									width: 100%;
									object-fit: cover;
								}
							}
						}
						::v-deep .edit-head {
							.form-title {
								.el-textarea__inner {
									// padding: 30px;
									font-size: 20px;
									text-align: center;
								}
							}
							.form-descript {
								.el-textarea__inner {
									padding: 5px 30px;
									font-size: 14px;
									color: #999;
									background-color: transparent;
								}
							}
							.el-textarea__inner {
								border: 1px dashed transparent;
								word-break: break-all;
								white-space: normal;
								font-family: inherit;
								border-radius: 0;
								cursor: pointer;
								&:hover,
								&:focus {
									border-color: var(--theme-color) !important;
								}
							}
						}
						.parent-wrap {
							flex: 1;
							padding: 20px 30px;
							.parent-list {
								display: block;
								width: 100%;
								height: 100%;
								.parent-item {
									.children-wrap {
										.children-list {
											display: flex;
											width: 100%;
											.children-item {
												flex: 1;
												position: relative;
												overflow: hidden;
												.cover {
													display: none;
													width: 100%;
													height: 100%;
													position: absolute;
													top: 0;
													left: 0;
													border: 1px solid #fff;
													z-index: 99;
													&:after {
														content: "";
														width: 100%;
														height: 100%;
														position: absolute;
														top: 0;
														left: 0;
														border: 1px dashed var(--theme-color);
														cursor: move;
														box-sizing: border-box;
													}
												}
												&:hover {
													.cover {
														display: block;
													}
													.widget_operate {
														display: flex;
													}
													overflow: unset;
												}
												.widget_operate {
													display: none;
													position: absolute;
													top: -15px;
													right: 10px;
													z-index: 999;
													.btn {
														display: flex;
														align-items: center;
														justify-content: center;
														width: 24px;
														height: 24px;
														border: 1px solid var(--theme-color);
														margin: 0 10px;
														color: var(--theme-color);
														border-radius: 50%;
														background-color: #fff;
														cursor: pointer;
														position: relative;
														&:hover {
															background-color: var(--theme-color);
															color: #fff;
															&:after {
																visibility: visible;
																opacity: 1;
																top: -25px;
																transition: 0.2s;
															}
														}
														&:after {
															content: attr(tip);
															visibility: hidden;
															opacity: 0;
															padding: 4px 8px;
															position: absolute;
															top: -35px;
															white-space: nowrap;
															font-size: 12px;
															background-color: #333;
															color: #fff;
															border-radius: 3px;
															transition: 0.2s;
														}
													}
													.el-icon-delete {
														border-color: var(--theme-orange-color);
														color: var(--theme-orange-color);
														&:hover {
															background-color: var(--theme-orange-color);
															color: #fff;
														}
													}
												}
											}
											.children-item + .children-item .item {
												border-left: none !important;
											}
											.children-item.active {
												overflow: unset;
												.widget_operate {
													display: flex;
												}
												.cover {
													display: block;
													&:after {
														background-color: rgba(0, 204, 255, 0.1);
													}
												}
											}
										}
									}
									.drag-site {
										height: 10px;
									}
								}
								.drag-tips {
									display: flex;
									align-items: center;
									justify-content: center;
									height: 100%;
									max-height: 300px;
									min-height: 200px;
									border: 1px dashed var(--theme-color);
									background-color: #f5f7f9;
									.iconfont {
										font-size: 20px;
										font-weight: bold;
										color: var(--theme-color);
									}
									.txt {
										margin: 0 0 0 10px;
										font-size: 16px;
										color: #666;
									}
								}
								.drag-tips.hide {
									display: none;
								}
								.d-chosen,
								.s-ghost {
									flex: 1 !important;
									min-height: 50px;
									position: relative !important;
									font-size: 0 !important;
									&::after {
										content: "放到这里";
										display: flex;
										align-items: center;
										justify-content: center;
										width: 100%;
										height: 100%;
										position: absolute;
										top: 0;
										left: 0;
										border: 1px dashed #f80;
										color: #f80;
										font-size: 15px;
										background-color: #fff4e8;
										box-sizing: border-box;
									}
								}
							}
						}
						.parent-wrap.is_dragging {
							.children-item:not(.s-ghost) {
								&:after {
									content: "";
									display: block;
									width: 100%;
									height: 100%;
									background-color: rgba(0, 0, 0, 0.2);
									position: absolute;
									top: 0;
									left: 0;
									z-index: 1;
								}
							}
						}
						.submit_btn {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 10px 0 30px;
							.el-button--primary {
								width: 100px;
								height: 40px;
								padding: 0;
							}
						}
					}
				}
				.set_area {
					width: 330px;
					border-left: 1px solid #e5e5e5;
					background-color: #fff;
					position: relative;
					z-index: 999;
					.close_btn {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 56px;
						border: 1px solid #e5e5e5;
						border-right: none;
						margin: -28px 0 0;
						border-radius: 5px 0 0 5px;
						position: absolute;
						top: 50%;
						left: -23px;
						background-color: #fff;
						box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);
						cursor: pointer;
						z-index: 9;
						.iconfont {
							font-size: 14px;
							color: #999;
							&:hover {
								color: var(--theme-color);
							}
						}
					}
					.set_list {
						height: 100%;
						user-select: none;
						.set_item {
							display: flex;
							flex-direction: column;
							width: 330px;
							height: 100%;
							.title {
								display: flex;
								align-items: center;
								height: 58px;
								padding: 0 24px;
								border-bottom: 1px solid #e5e5e5;
								font-size: 16px;
								white-space: nowrap;
							}
							.list {
								flex: 1;
								overflow-y: auto;
								padding: 0 10px;
								.item {
									padding: 0 5px;
									border-bottom: 1px solid #eee;
									.switch_btn {
										display: flex;
										align-items: center;
										justify-content: space-between;
										height: 50px;
										.label {
											display: flex;
											align-items: center;
											.explain {
												position: relative;
												.el-icon-info {
													display: block;
													margin: 0 0 0 5px;
													color: #aaa;
													cursor: pointer;
													font-size: 18px;
												}
												.cont {
													visibility: hidden;
													opacity: 0;
													background-color: #fff;
													box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
													position: fixed;
													margin: -37px 0 0 -410px;
													z-index: 99;
													transition: all 0.3s;
													.angle {
														width: 10px;
														height: 10px;
														transform: rotate(45deg);
														background: #fff;
														box-shadow: 3px -3px 5px rgba(0, 0, 0, 0.1);
														position: absolute;
														right: -6px;
														top: 20px;
													}
													.tips-main {
														display: flex;
														align-items: center;
														width: 390px;
														padding: 20px 18px;
														.imgbox {
															img {
																display: block;
																width: 200px;
															}
														}
														.infobox {
															margin: 0 0 0 10px;
															.tips-head {
																line-height: 21px;
																color: #707070;
																padding-bottom: 10px;
															}
															.tips-txt {
																font-size: 12px;
																line-height: 18px;
																color: #333;
																.p1 {
																	color: #999;
																}
															}
														}
													}
												}
												&:hover {
													.cont {
														visibility: visible;
														opacity: 1;
														margin-left: -395px;
													}
													.el-icon-info {
														color: var(--theme-color);
													}
												}
											}
										}
										.fold_btn {
											display: flex;
											align-items: center;
											font-size: 13px;
											color: #999;
											cursor: pointer;
											.iconfont {
												font-size: 14px;
												margin: 0 0 0 3px;
												transition: all 0.2s;
											}
											&:hover {
												color: var(--theme-color);
											}
										}
										.fold_btn.active {
											.iconfont {
												margin-top: -3px;
												transform: rotate(-180deg);
												transition: all 0.2s;
											}
										}
									}
									.sub_list {
										padding: 0 10px;
										margin: 0 0 10px;
										background-color: #f5f5f5;
										overflow: hidden;
										.sub_item {
											display: flex;
											justify-content: space-between;
											padding: 10px 0;
											border-top: 1px dashed #ddd;
											.sub_label {
												line-height: 28px;
												color: #888;
												font-size: 13px;
											}
											&:first-child {
												border: none;
											}
										}
									}
									.el-input__inner {
										width: 200px;
										height: 28px;
										font-size: 13px;
										padding: 0 5px;
									}
									.el-input__icon {
										line-height: 28px;
									}
									.el-color-picker {
										display: block;
										margin: 0 0 0 5px;
										border-radius: 2px;
										background-color: #fff;
									}
									.el-textarea {
										width: 200px;
										.el-textarea__inner {
											padding: 5px;
											font-family: inherit;
											font-size: 13px;
										}
									}
									.avatar-uploader {
										.el-upload {
											display: flex;
											align-items: center;
											justify-content: center;
											width: 70px;
											height: 70px;
											border: 1px dashed #d9d9d9;
											border-radius: 3px;
											cursor: pointer;
											.avatar{
												width: 100%;
												height: 100%;
												position: relative;
												img{
													width: 100%;
													height: 100%;
													object-fit: contain;
												}
												.el-icon-error{
													font-size: 20px;
													background-color: #fff;
													border-radius: 50%;
													color: #333;
													position: absolute;
													top: -8px;
													right: -8px;
													z-index: 9;
												}
											}
											.avatar-uploader-icon {
												font-size: 28px;
												color: #8c939d;
											}
											&:hover {
												border-color: var(--theme-color);
											}
										}
									}
									.text_style {
										display: flex;
										.iconfont {
											line-height: 28px;
											margin: 0 0 0 5px;
											color: #888;
											cursor: pointer;
										}
										.iconfont.active {
											color: var(--theme-color);
										}
										.el-input__inner {
											width: 104px !important;
										}
									}
									.silder {
										width: 200px;
										padding: 0px 8px 0 0;
										.el-slider__runway {
											margin: 11px 0;
											.el-slider__bar {
												background-color: var(--theme-color);
											}
											.el-slider__button {
												border-color: var(--theme-color);
											}
										}
									}
									.border_style {
										display: flex;
										.el-input__inner {
												width: 60px;
										}
										.el-select {
											.el-input__inner {
												width: 102px;
												margin: 0 0 0 5px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.question_types.mobile{
		.main_area{
			.edit_cont{
				.edit_wrap{
					padding: 0;
					overflow-y: auto;
					.m_edit_inner{
						width: 447px;
						height: 863px;
						padding: 91px 36px 105px;
						margin: 0 auto;
						background: url(../../../assets/images/appear-mobile.png);
						overflow: hidden;
						.scroll_inner{
							height: 100%;
							border: 1px solid #ddd;
							overflow-y: auto;
							.mform-head{
								.img{
									img{
										width: 100%;
										object-fit: cover;
									}
								}
							}
							.mform-title{
								text-align: center;
							}
							.mform-widget{
								.mform-list{
									.mform-item{
										.mform-sub{
											position: relative;
											.item.divider{
												border: none!important;
											}
											.cover{
												width: 100%;
												height: 100%;
												position: absolute;
												top: 0;
												left: 0;
											}
										}
									}
								}
								.no_data{
									margin: 150px 0 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>